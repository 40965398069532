import { useIsCompact } from "hooks/useIsCompact";
import React from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";

export function Page({ light, fixed, children, locationSearch }) {
  const isCompact = useIsCompact(locationSearch);

  return (
    <React.Fragment>
      {!isCompact && <Header light={light} fixed={fixed} />}
      {children}
      {!isCompact && <Footer />}
    </React.Fragment>
  );
}
