import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { useMediaQuery } from "hooks/useMediaQuery";

const links = [
  {
    to: "/societies",
    children: "Societies",
  },
  {
    to: "mailto:info@exeterguild.com",
    external: true,
    children: "Contact",
  },
  {
    to: "https://www.exeterguild.com/",
    external: true,
    children: "Main website",
  },
];

export function Header({ light = false, fixed = false }) {
  const { pathname } = useLocation();

  const isLarge = useMediaQuery("(min-width: 800px)");

  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    setNavOpen(false);
  }, [pathname]);

  useEffect(() => {
    if (isLarge) {
      setNavOpen(false);
    }
  }, [isLarge]);

  return (
    <header
      className={`header ${light ? "header--light" : ""}  ${
        fixed ? "header--fixed" : ""
      }
      ${navOpen ? "header--open" : ""}
      `}
    >
      <div className="header__content">
        <Link to="/">
          <img
            alt="University of Exeter student's guild events"
            className="header__logo"
            src="/logoColor.png"
          />
        </Link>
        <nav className="header__nav">
          {links.map((link) => {
            if (link.external) {
              return (
                <a
                  key={link.to}
                  href={link.to}
                  target="_blank"
                  rel="noreferrer"
                >
                  {link.children}
                </a>
              );
            } else {
              return (
                <Link key={link.to} to={link.to} activeClassName="active">
                  {link.children}
                </Link>
              );
            }
          })}
        </nav>
        <button className="header-toggle" onClick={() => setNavOpen(!navOpen)}>
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
    </header>
  );
}
