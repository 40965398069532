import React from "react";

export function Footer() {
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__row">
          <img className="footer__logo" src="/logoColor.png" alt="" />
          <div className="footer__socials">
            <a
              className="footer__social footer__social--insta"
              href="https://www.instagram.com/exeterguild"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/socialInsta.svg" alt="Instagram" />
            </a>
            <a
              className="footer__social footer__social--facebook"
              href="https://www.facebook.com/exeterguild"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/socialFacebook.svg" alt="Facebook" />
            </a>
            <a
              className="footer__social footer__social--twitter"
              href="https://twitter.com/exeterguild"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/socialTwitter.svg" alt="Twitter" />
            </a>
          </div>
        </div>
        <div className="footer__row footer__info">
          <span>© university of exeter {new Date().getFullYear()}</span>
          <div>
            <a
              href="https://www.exeterguild.org/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              <span>Privacy Policy</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
